import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import NavajoZillaDesktop from "../Navajo/NavajoZillaDesktop";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      margin: "40px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "60px 0px",
    },
  },
  profile: {
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "90px",
      display: "flex",
      alignItems: "center",
    },
  },
  navajo: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      display: "flex",
      width: "320px",
      height: "100px",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "728px",
      height: "90px",
      justifyContent: "flex-end",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
}));

const Profile = props => {
  const { title, additionalTitle } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.profile}>
          <div className={classes.header}>
            <span>
              <Typography className={classes.headerTitle} variant="h1">
                {title} <br /> {additionalTitle}
              </Typography>
            </span>
          </div>
          <div className={classes.navajo}>
            <NavajoZillaDesktop />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Profile;
