import React from "react";
// import { useStaticQuery, graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import AllPools from "./AllPools";

// const useAllPools = () => {
//   const { allAllPoolsJson } = useStaticQuery(
//     graphql`
//       query {
//         allAllPoolsJson {
//           nodes {
//             name
//             numberOfSubPools
//             slug
//             isVerified
//           }
//         }
//       }
//     `
//   );
//   return allAllPoolsJson.nodes.map(pool => {
//     return {
//       numberOfSubPools: pool.numberOfSubPools,
//       name: pool.name,
//       slug: pool.slug,
//       isVerified: pool.isVerified,
//     };
//   });
// };

const AllPoolsPage = ({ pageContext }) => {
  // const pools = useAllPools();
  const { pools } = pageContext;

  return (
    <Root>
      <SEO
        title="List Of All Cryptocurrency Mining Pools"
        description="View a list of all cryptocurrency mining pools and check what coins you can mine using their services."
      />
      <AllPools pools={pools} />
    </Root>
  );
};

export default AllPoolsPage;
